import React, {useCallback, useEffect, useState} from 'react';
import {
  // Carousel,
  Col,
  Spin} from 'antd';
// import {Typography} from '@/components/common-components';
import {Form} from 'antd';
import {useTranslation} from 'next-i18next';
import {authApi, profileApi} from '@/api/account';
import {notification} from '@/utils/notifications';
import {useRouter} from 'next/router';
import {setTokenIntoCookies, setHasRegisteredIntoStorage, removeWhiteLabelOtto} from '@/api/common-utils';
import {LoadingOutlined} from '@ant-design/icons';
import Link from 'next/link';

import styles from './styles.module.scss';

import {MixPanel} from '@/utils/mixpanel';
import {routes} from '@/utils/const';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {getDomain} from '@/utils/url';
import {linkgraphDomains, openUrl} from '@/utils/router';
import {AccountInput, ContinueButton, FormContainer, HeadingAnimationWrapper, StyledFormItem} from '../register-c/helpers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/pro-solid-svg-icons';
import GoogleAuthButton from '@/components/common-components/components/google-auth-button';
import {NextImg} from '@/utils/nextImg';
import styled from 'styled-components';
import {LogoWrapper} from '../components/logo-wrapper/logoWrapper';

interface Props {
  isCheckoutPage?: boolean;
  openRegisterPage?: (value) => void;
}

export const LoginPage: React.FC<Props> = observer(({isCheckoutPage, openRegisterPage}) => {
  const {settings: {integration: {gscAccountsConnected}}}= useStore('');
  const {settings: {customer: {profile: {brandColor, isVendastaClient, setLogoInStore, setSignInLogoInStore, setSignUpLogoInStore, signUpLogo, setBrandColor, domainApiLoading}}}} = useStore('');
  const {t} = useTranslation('common');
  const router = useRouter();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState('');
  const [pageLoading, setPageLoading] =useState(false);
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);

  const onLogin = async ({email, password}: {email: string; password: string}) => {
    setLoading(true);
    try {
      const data = await authApi.login(email, password);
      setHasRegisteredIntoStorage();
      setTokenIntoCookies(data.token);
      removeWhiteLabelOtto();

      MixPanel.identify(`${data.userId}`);
      if (!gscAccountsConnected && isVendastaClient) {
        router.push('/gsc/sites-list');
      } else {
        router.push(routes?.home);
      }
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
        notification.error(t('invalid-credits'));
      } else {
        notification.error(t('went-wrong'));
      }
    }
    setLoading(false);
  };

  // LEAVING THIS COMMENTED IN THE CASE WE WANNA GO BACK TO USING THIRD-PARTY COOKIE BASED GOOGLE LOGIN
  // const onGoogleOAuthSuccess = async (payload: GoogleLoginResponse | GoogleLoginResponseOffline) => {
  //   const response = await authApi.validateGoogleOAuthResponseAndLogin({
  //     ...payload,
  //     lead: leadCaptureData,
  //   });
  //   setHasRegisteredIntoStorage();
  //   setTokenIntoCookies(response.data.token);
  //   navBar.setIsGoogleUser();
  //   router.push('/');
  // };

  // const onGoogleOAuthFailure = (error: any) => {
  //   notification.error(error);
  // };
  const getLogo = async () => {
    setPageLoading(true);
    const logos = await profileApi.getDomainLogos();
    if (logos) {
      setLogo(`${logos?.logoUrl}`);
      setLogoInStore(`${logos?.logoUrl}`);
      setSignInLogoInStore(`${logos?.signupLogoUrl || logos?.logoUrl}`);
      setSignUpLogoInStore(`${logos?.signupLogoUrl || logos?.logoUrl}`);
      setBrandColor(logos?.brandColor);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (!logo) {
      getLogo();
    }
  }, [location.href]);

  const handleGoogleRedirect = useCallback(
    () => {
      openUrl(`https://api.searchatlas.com/account/google/oauth/start?from_domain=${window.location.host}`, '_blank');
    },
    [],
  );

  const diff = (location?.origin?.includes('dashboard.linkgraph') || location?.origin?.includes('dashboard.searchatlas') || location?.origin?.includes('localhost'));

  const validatePassword = (_, value) => {
    if (!value.length) {
      return Promise.reject(new Error('Enter a password'));
    }
    if (/\s/.test(value)) {
      return Promise.reject(new Error('Password cannot contain spaces'));
    }
    return Promise.resolve();
  };

  return (
    <>
      {(isWhiteLabelDomain && (domainApiLoading || pageLoading)) ? <></> : <div className={styles.signUpBg}>
        {(isWhiteLabelDomain && brandColor) && (
          <>
            <div className={styles.bgBack}></div>
            <div className={styles.bgFront} style={{'--brand-color': brandColor} as any}></div>
          </>
        )}
        <LogoWrapper>
          <img className={styles.logo} style={{position: 'relative', zIndex: 3, width: '298px', height: 'auto', marginBottom: '98px'}} src={isWhiteLabelDomain ? (signUpLogo ?? logo ) : '/img/registerBg/newRegisterLoginLogo.svg'} />
        </LogoWrapper>
        <div className={styles.descriptionContainer}>
          <div className={styles.container}>
            <HeadingAnimationWrapper>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <p className={styles.text}>Automate your SEO</p>&nbsp;
                {isWhiteLabelDomain ? '' : <div className={styles.descLineTwo}>with Search Atlas.</div>}
              </div>
            </HeadingAnimationWrapper>
            <div id='console' className='console-underscore'></div>
          </div>
          <div className={styles.descriptionWrapper}>
            <div className={styles.description}>37 cutting-edge SEO, public relations and backlink tools to start ranking quickly.</div>
            <div className={styles.description}>Stop wasting time and money on your SEO and transform your strategy in 7 days.</div>
          </div>
        </div>
        <FormContainer brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''}>
          <div className={styles.formTitle}>{diff ? <span style={{textAlign: 'center'}}>Login to your existing Search Atlas account.</span> : 'Welcome Back'}</div>
          <StyledForm className={styles.form} onFinish={onLogin}>
            <Col className={styles.emailInput} >
              <Col>
                <div className={styles.label}>Email</div>
              </Col>
              <Col>
                <StyledFormItem
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter an email',
                    },
                    {
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                  ]}
                >
                  <AccountInput
                    placeholder={'hello@exampleemail.com'}
                    name='email'
                    disabled={loading}
                    className={styles.inputOverrides}
                    type='email'
                  />
                </StyledFormItem>
              </Col>
            </Col>
            <Col className={styles.emailInput} >
              <Col>
                <div className={styles.label}>Password</div>
              </Col>
              <Col>
                <StyledFormItem
                  name='password'
                  rules={[
                    {validator: validatePassword},
                  ]}>
                  <AccountInput
                    name='password'
                    placeholder={'Type your password'}
                    disabled={loading}
                    suffix={showPass ? <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faEyeSlash} onClick={() => setShowPass(false)} /> : <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faEye} onClick={() => setShowPass(true)} /> }
                    type={showPass ? 'text' : 'password'}
                  />
                </StyledFormItem>
              </Col>
            </Col>
            <div className={styles.forgotPass}>
              <Link href='forgot-password'>Forgot Password?</Link>
            </div>
            <Col>
              <ContinueButton brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''} style={{marginBottom: isWhiteLabelDomain ? '47px' : ''}} htmlType='submit' disabled={loading} loadingStyle={loading ? 'opacity: 0.7;' : ''}>
                <div>Login with email</div>
                {loading ? <Spin indicator={<LoadingOutlined style={{color: '#ffff', fontSize: '20px'}} spin />} /> : <NextImg src='/img/registerBg/searchAtlasIcon.svg' alt={'searchAtlasIcon'} width={16} height={16} />}
              </ContinueButton>
            </Col>
            {!isWhiteLabelDomain && <><Col>
              <div className={styles.or}>OR</div>
            </Col>
            <Col style={{marginBottom: isWhiteLabelDomain ? '3rem' : ''}}>
              <GoogleAuthButton onClick={handleGoogleRedirect} />
            </Col></>}
            {isWhiteLabelDomain ? '' : <>
              <div>
                <p className={styles.signUp}>
                  {t('not-registered')}{' '}
                  {isCheckoutPage ? <span style={{color: '#1890ff', cursor: 'pointer'}} onClick={() => openRegisterPage(true)}>{t('create-account')}</span> :
                    <Link legacyBehavior
                      href='register'>
                      <a data-cy='toRegister'>
                        {t('create-account')}
                      </a>
                    </Link>}
                </p>
              </div>
            </>}


          </StyledForm>
        </FormContainer>
        <div className={styles.footerTxtContainer}>
          <div className={styles.footerTxt}>Content. Technicals. Automation.</div>
          <div className={styles.footerTxt}>Everything you need for SEO in one place.</div>
        </div>
      </div>}
    </>
  );
});

export const StyledForm = styled(Form)`
  @media screen and (max-width: 600px) {
    width: 90% !important;
  }
`;
